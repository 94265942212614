<template>
  <div class="about mx-5">
    <hero-alt />
    <base-section id="theme-features">
    <base-section-heading title="Nosotros">
      
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col>
          <div class="content">
              <p>
                  <strong>Monitor Satelital</strong> es una empresa dedicada a la instalación y venta de equipo de GPS (Global Positioning System), sistema de posicionamiento global, para flotillas de cualquier tamaño he incluso para uso personal.<br>
              </p>
              <p>
                  El sistema de <strong>Monitor Satelital</strong> esta desarrollado para un fácil manejo y monitero en vivo las 24 horas del dia. El monitoreo en vivo se actualiza cada minuto* y se ve reflejado en el mapa cada dispositivo en cualquier región del país con la habilidad de dar acercamientos a nivel de calle.<br>
                  Se cuenta con un registro histórico de posiciones para que el usuario pueda acudir la posicion o recorrido que dio en cualquier momento.
              </p>
              <p>
                  El sistema esta automatizado para enviar alertas de sobrepaso de velocidad, de distancia recorrida, de cuando se enciende o apaga el motor, cuando se abre o cierra la puerta y hasta cuando el dispositivo esta por terminarse la bateria, al correo electrónico proporcionado.<br>
              </p>
              <p>
                  Ubicado en la ciudad de Monterrey con un alcanze a toda la república mexicana. Para que los beneficios de nuestro sistema este al alcance de cualquier persona.
              </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>


  <base-business-info>
    
  </base-business-info>

  </div>
</template>
<script>

export default {
  components: {
    HeroAlt: () => import('./sections/HeroAlt'),
  },
  metaInfo: { title: 'Nosotros' },
  name: "Nosotros",
}
</script>
<style>
</style>

